<template>
  <div>
    <HeaderPanel
      title="Channel Analysis"
      :filter="filter"
      @sidebar="sidebarFilter()"
      :hasDropdown="false"
      :hasSearch="false"
      :hasExport="true"
      @export="exportExcel('channel_customer_file')"
    />
    <p class="my-2 f-16">
      Total Customer: {{ stat.totalCustomer | numeral("0,0") }} ({{
        stat.percentCustomer
      }}%)
    </p>
    <p class="f-16">
      {{ handleDateRange }}
    </p>
    <div class="mt-3">
      <StatPanel :column="statColumn" :data="stat" :isLoading="statLoading" />

      <div class="bg-white p-3">
        <b-card class="card-shadow border-none text-black rounded-lg">
          <div
            class="text-right text-highlight mb-2 text-underline cursor-pointer"
            @click="exportExcel('ReportStoreChannel')"
          >
            Export
          </div>
          <b-table
            fixed
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
            foot-clone
            no-footer-sorting
            @sort-changed="sortingChanged"
            :sort-by.sync="filterTable.sortBy"
            no-local-sorting
            :sort-desc.sync="filterTable.sortDesc"
          >
            <template #cell(revenue)="{ item }">
              <span>
                {{ item.revenue | numeral("0,0.00") }}
                ({{
                  ((item.revenue / subTotal.sotreWithChannel["revenue"]) * 100)
                    | numeral("0.00")
                }}%)
              </span>
            </template>
            <template #cell(branchUPT)="{ item }">
              {{ item.branchUPT | numeral("0,0.00") }}
            </template>
            <template #cell(branchATV)="{ item }">
              {{ item.branchATV | numeral("0,0.00") }}
            </template>
            <template #cell(units)="{ item }">
              <span>
                {{ item.units | numeral("0,0") }}
                ({{
                  ((item.units / subTotal.sotreWithChannel["units"]) * 100)
                    | numeral("0.00")
                }}%)
              </span>
            </template>
            <template #cell(bills)="{ item }">
              {{ item.bills | numeral("0,0") }}
              ({{
                ((item.bills / subTotal.sotreWithChannel["bills"]) * 100)
                  | numeral("0.00")
              }}%)
              <!-- {{ item.bills | numeral("0,0") }} ({{ item.percenBills }}%) -->
            </template>
            <template #cell(members)="{ item }">
              {{ item.members | numeral("0,0") }} ({{
                ((item.members / subTotal.sotreWithChannel["members"]) * 100)
                  | numeral("0.00")
              }}%)
            </template>
            <template #cell(newMembers)="{ item }">
              {{ item.newMembers | numeral("0,0") }} ({{
                ((item.newMembers / subTotal.sotreWithChannel["newMembers"]) *
                  100)
                  | numeral("0.00")
              }}%)
            </template>
            <template v-slot:foot()="data">
              <span v-if="data.field.key == 'branchATV'">{{
                subTotal.sotreWithChannel.atv | numeral("0,0.00")
              }}</span>
              <span v-else-if="data.field.key == 'branchUPT'">{{
                subTotal.sotreWithChannel.upt | numeral("0,0.00")
              }}</span>
              <span
                v-else-if="subTotal.sotreWithChannel[data.field.key]"
                class="text-center"
              >
                <span
                  v-if="
                    data.field.key == 'units' ||
                    data.field.key == 'bills' ||
                    data.field.key == 'members' ||
                    data.field.key == 'newMembers'
                  "
                >
                  {{
                    subTotal.sotreWithChannel[data.field.key] | numeral("0,0")
                  }}</span
                >
                <span v-else>
                  {{
                    subTotal.sotreWithChannel[data.field.key]
                      | numeral("0,0.00")
                  }}</span
                >
              </span>
              <span v-else>
                <div class="text-left px-2" v-if="data.field.text">
                  {{ data.field.text }}
                </div></span
              >
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
          <Pagination
            @handleChangeTake="handleChangeTake"
            @pagination="pagination"
            :filter="filterTable"
            :rows="rows"
            :limit="2"
        /></b-card>
        <b-row class="my-3">
          <b-col md="6">
            <b-card class="card-shadow border-none text-black rounded-lg">
              <div>
                <div class="content-between mb-2">
                  <div class="chart-title-name">Age</div>
                  <div class="d-flex" style="column-gap: 5px">
                    <div :class="`f-14 underline cursor-pointer`">
                      <!-- @click="hadleChartFilter(chartFilter, chart)" -->
                      <span
                        class="text-highlight"
                        @click="exportExcel('ReportCustomerAgeGroup')"
                        >Export</span
                      >
                    </div>
                  </div>
                </div>

                <Chart
                  id="age-chart"
                  :chartData="chartAgeData"
                  text="Number Of Customer"
                  class="mt-auto"
                  :label="chartAgeLabel"
                  :hideLegend="true"
                  v-if="!loading"
                />
              </div>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card class="card-shadow border-none text-black rounded-lg">
              <div>
                <div class="content-between mb-2">
                  <div class="chart-title-name">Gender</div>
                  <div class="d-flex" style="column-gap: 5px">
                    <div :class="`f-14 underline cursor-pointer`">
                      <!-- @click="hadleChartFilter(chartFilter, chart)" -->
                      <span
                        class="text-highlight"
                        @click="exportExcel('ReportCustomerGender')"
                        >Export</span
                      >
                    </div>
                  </div>
                </div>

                <Chart
                  id="gender-chart"
                  :chartData="chartGenderData"
                  text="Number Of Customer"
                  class="mt-auto"
                  :label="chartGenderLabel"
                  :hideLegend="true"
                  v-if="!loading2"
                />
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-card class="card-shadow border-none text-black rounded-lg">
          <div class="content-between mb-2 mb-2">
            <div class="chart-title-name">Product</div>
            <div class="d-flex align-items-center" style="column-gap: 5px">
              <b-form-input
                type="text"
                id="header-search-bar"
                class="search-bar"
                @keyup.enter="handleSearch"
                placeholder="Search Name, Barcode"
                v-model="filterProductTable.search"
                style="width: 300px"
              >
              </b-form-input>
              <div :class="`f-14 underline cursor-pointer`">
                <!-- @click="hadleChartFilter(chartFilter, chart)" -->
                <span
                  class="text-highlight"
                  @click="exportExcel('ReportProduct')"
                  >Export</span
                >
              </div>
            </div>
          </div>
          <b-table
            fixed
            striped
            hover
            :fields="fieldsProduct"
            :items="itemsProduct"
            :busy="isProductBusy"
            show-empty
            foot-clone
            no-footer-sorting
            empty-text="No matching records found"
            @sort-changed="sortProduct"
            :sort-by.sync="filterProductTable.sortBy"
            no-local-sorting
            :sort-desc.sync="filterProductTable.sortDesc"
          >
            <template v-slot:cell(totalCustomer)="data">
              <span>
                {{ data.item.totalCustomer | numeral("0,0") }}
                ({{
                  ((data.item.totalCustomer /
                    subTotal.product["totalCustomer"]) *
                    100)
                    | numeral("0.00")
                }}%)
              </span>
            </template>

            <template #cell(totalSales)="{ item }">
              {{ item.totalSales | numeral("0,0.00") }}
            </template>
            <template #cell(totalQuantity)="{ item }">
              {{ item.totalQuantity | numeral("0,0") }}
            </template>
            <template #cell(totalBill)="{ item }">
              {{ item.totalBill | numeral("0,0") }}
            </template>
            <template #cell(atv)="{ item }">
              {{ item.atv | numeral("0,0.00") }}
            </template>
            <template #cell(members)="{ item }">
              {{ item.members | numeral("0,0") }} ({{ item.percenMembers }}%)
            </template>
            <template v-slot:cell(percenSalesContribution)="data">
              <span>
                {{
                  ((data.item.totalSales / subTotal.product["totalSales"]) *
                    100)
                    | numeral("0.00")
                }}%
              </span>
            </template>
            <template v-slot:foot()="data">
              <span v-if="subTotal.product[data.field.key]" class="text-center">
                <span
                  v-if="
                    data.field.key == 'totalCustomer' ||
                    data.field.key == 'totalQuantity' ||
                    data.field.key == 'totalBill'
                  "
                >
                  {{ subTotal.product[data.field.key] | numeral("0,0") }}</span
                >
                <span v-else>
                  {{
                    subTotal.product[data.field.key] | numeral("0,0.00")
                  }}</span
                >
              </span>
              <span v-else>
                <div class="text-left px-2" v-if="data.field.text">
                  {{ data.field.text }}
                </div></span
              >
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
          <Pagination
            @handleChangeTake="handleProductChangeTake"
            @pagination="paginationProduct"
            :filter="filterProductTable"
            :rows="rowsProduct"
            :limit="2"
        /></b-card>
      </div>
    </div>
    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      :placeholder="'Search Name'"
      @clearFilter="clearFilter"
      @searchAll="filterActions"
      :hideStatusFilter="false"
      :hideSearchBar="true"
      v-if="!isFilterLoading"
    >
      <template v-slot:filter-option>
        <FilterCustomDate
          customText="Transaction Date"
          id="9"
          :from="filter.TransactionDateFrom"
          :to="filter.TransactionDateTo"
          :type="filter.LastType"
          @update:type="(value) => (filter.LastType = value)"
          @update:from="(value) => (filter.TransactionDateFrom = value)"
          @update:to="(value) => (filter.TransactionDateTo = value)"
        />
        <!-- <div>
          <InputSelect
            title="Transaction Date"
            name="transaction-date"
            class="mt-2"
            v-model="filter.LastType"
            :options="options.dateType"
            valueField="value"
            textField="text"
          >
            <template v-slot:option-first>
              <b-form-select-option value="" disabled
                >-- Select Category --</b-form-select-option
              >
            </template>
          </InputSelect>
        </div>
        <div v-if="filter.LastType == 0">
          <InputDatePickerFilter
            textFloat="Transaciton Date From"
            name="transaction-date-from"
            ref="transaction-date-from"
            :value="filter.TransactionDateFrom"
            @input="(val) => (filter.TransactionDateFrom = val)"
          />
        </div>
        <div v-if="filter.LastType == 0">
          <InputDatePickerFilter
            textFloat="Transaciton Date To"
            name="transaction-date-to"
            ref="transaction-date-to"
            :min-datetime="filter.TransactionDateFrom"
            :value="filter.TransactionDateTo"
            @input="(val) => (filter.TransactionDateTo = val)"
          />
        </div> -->
        <div class="my-3">
          <MultiSelect
            used="tier"
            textFloat="Member tier"
            :options="options.memberTiers"
            textField="memberTierName"
            valueField="id"
            placeholder="Select Member Tier"
            v-model="filter.MemberTierFilter"
            :value="filter.MemberTierFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            textFloat="Product"
            :options="options.product"
            textField="productName"
            valueField="id"
            placeholder="Select Product"
            v-model="filter.ProductFilter"
            :value="filter.ProductFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="channel"
            textFloat="Channel"
            :options="options.channelTypes"
            textField="channelName"
            valueField="id"
            placeholder="Select Channel"
            v-model="filter.ChannelFilter"
            :value="filter.ChannelFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            textFloat="Branch"
            :options="options.branches"
            textField="branchName"
            valueField="id"
            placeholder="Select Branch"
            v-model="filter.BranchFilter"
            :value="filter.BranchFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="age"
            textFloat="Age Group"
            :options="options.ageGroups"
            textField="ageGroupName"
            valueField="id"
            placeholder="Select Age Group"
            v-model="filter.AgeGroupFilter"
            :value="filter.AgeGroupFilter"
          />
        </div>
      </template>
    </SideBarFilter>
  </div>
</template>

<script>
import Chart from "./chart";
function getRandomColor() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return "rgb(" + r + "," + g + "," + b + ")";
}
function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
        result[3],
        16
      )})`
    : null;
}

export default {
  components: { Chart },
  data() {
    return {
      isBusy: false,
      isProductBusy: false,
      isFilterLoading: true,
      statColumn: [
        {
          name: "Total Bill",
          key: "totalBill",
          isPercent: false,
          isDecimal: false,
        },
        {
          name: "Total Sales",
          key: "totalRevenue",
          isPercent: false,
          isDecimal: false,
        },
        {
          name: "Total Unit",
          key: "totalUnit",
          isPercent: false,
          isDecimal: false,
        },
        {
          name: "ATV",
          key: "atv",
          isPercent: false,
          isDecimal: true,
        },
        {
          name: "UPT",
          key: "upt",
          isPercent: false,
          isDecimal: true,
        },
      ],
      statLoading: false,
      fields: [
        {
          key: "branchName",
          label: "Store",
          class: "w-2",
          sortable: true,
          text: "Total",
        },
        { key: "channelName", label: "Channel", sortable: true },
        { key: "revenue", label: "Total Sales", class: "w-2", sortable: true },
        { key: "branchATV", label: "ATV", class: "w-1", sortable: true },
        { key: "branchUPT", label: "UPT", class: "w-1", sortable: true },
        { key: "units", label: "Units", class: "w-1", sortable: true },
        { key: "bills", label: "Bills", class: "w-1", sortable: true },
        { key: "members", label: "Members", class: "w-1" },
        {
          key: "newMembers",
          label: "New Members",
          class: "w-1",
        },
      ],
      fieldsProduct: [
        {
          key: "barcode",
          label: "Barcode",
          class: "w-2",
          sortable: true,
          text: "Total",
        },
        { key: "productName", label: "Name", class: "w-3", sortable: true },
        {
          key: "totalCustomer",
          label: "% Customer",
          class: "w-1",
          sortable: true,
        },
        {
          key: "totalSales",
          label: "Total Sales",
          class: "w-1",
          sortable: true,
        },
        {
          key: "totalQuantity",
          label: "Total Quantity",
          class: "w-1",
          sortable: true,
        },
        {
          key: "totalBill",
          label: "Total Bills",
          class: "w-1",
          sortable: true,
        },
        { key: "atv", label: "ATV", class: "w-1", sortable: true },
        {
          key: "percenSalesContribution",
          label: "% Sales Contribution",
          class: "w-10pc",
          sortable: true,
        },
      ],
      stat: {
        totalCustomer: 0,
        percentCustomer: 0,
        totalBill: 0,
        totalRevenue: 0,
        totalUnit: 0,
        atv: 0,
        upt: 0,
      },
      subTotal: {
        sotreWithChannel: {
          revenue: 0,
          atv: 0,
          upt: 0,
          units: 0,
          bills: 0,
          members: 0,
          newMembers: 0,
        },
        product: {
          totalCustomer: 0,
          totalSales: 0,
          totalQuantity: 0,
          atv: 0,
        },
      },
      filter: {
        ChannelFilter: this.$store.state.filter.channel,
        BranchFilter: this.$store.state.filter.branch,
        AgeGroupFilter: this.$store.state.filter.age,
        GenderFilter: this.$store.state.filter.gender,
        ProductFilter: this.$store.state.filter.product,
        MemberTierFilter: this.$store.state.filter.tier,

        TransactionDateFrom: this.$store.state.filter.from,
        TransactionDateTo: this.$store.state.filter.to,
        TypeDateFilter: 2,
        LastType: 0,
        LastType: this.$store.state.filter.dateType,
      },

      filterTable: {
        page: 1,
        take: 10,
        search: "",
        SortColumnId: 0,
        SortType: true,
        sortBy: "",
        sortDesc: "",
      },
      filterProductTable: {
        page: 1,
        take: 10,
        search: "",
        SortColumnId: 0,
        SortType: true,
        sortBy: "",
        sortDesc: "",
      },
      loading: true,
      loading2: true,
      items: [],
      itemsProduct: [],
      rows: 0,
      rowsProduct: 0,
      chartAgeData: [],
      chartAgeLabel: [],
      chartGenderData: [],
      chartGenderLabel: [],
      options: {
        memberTiers: [],
        channelTypes: [],
        branches: [],
        ageGroups: [],
        genderModels: [],
        dateType: [
          { text: "Last 7 Days", value: 7 },
          { text: "Last 30 Days", value: 30 },
          { text: "Last 365 Days", value: 365 },
          { text: "Custom", value: 0 },
        ],
        // product: [],
        // transactionDate: [],
      },
    };
  },
  created() {
    this.getFilter();
    this.getData();
  },
  computed: {
    handleDateRange() {
      if (this.filter.LastType == 0)
        return `${this.$moment(this.filter.TransactionDateFrom).format(
          "DD MMM YYYY"
        )} - ${this.$moment(this.filter.TransactionDateTo).format(
          "DD MMM YYYY"
        )}`;
      else return this.$displayFilterDate(this.filter.LastType);
    },
  },
  methods: {
    async getData() {
      this.$bus.$emit("showLoading");
      const res = await this.$report.post(
        `/RevenueReport/channel/default`,
        this.filter
      );
      // return await Promise.all([
      this.getStat(res.data.detail.result_static);
      this.getStore();
      this.getGender(res.data.detail.result_gender);
      this.getTotal();
      this.getAge(res.data.detail.result_age);
      this.getProduct();
      this.$bus.$emit("hideLoading");
      // ]);
    },

    async getStat(data) {
      this.statLoading = true;
      var respData = data;
      if (!data) {
        const res = await this.$report.post(
          `/RevenueReport/RevenueStatic`,
          this.filter
        );
        respData = res.data.detail;
      }
      this.stat = respData;
      this.statLoading = false;
    },
    async getProductFilter() {
      const res = await this.$report.post(
        `/RevenueReport/CustomProductFilter`,
        {
          SearchProduct: "",
          PageNumber: 1,
          RecordPerpage: 99999,
        }
      );

      this.options.product = res.data.productFilterModels.map((el) => {
        return { id: el.id, productName: `${el.barcode} / ${el.productName}` };
      });
    },
    async getFilter() {
      const res = await this.$report(`/RevenueReport/CustomFilter`);

      this.options = {
        ...res.data,
        dateType: [
          { text: "Last 7 Days", value: 7 },
          { text: "Last 30 Days", value: 30 },
          { text: "Last 365 Days", value: 365 },
          { text: "Custom", value: 0 },
        ],
        product: [],
      };
      await this.getProductFilter();
      this.isFilterLoading = false;
    },
    async getStore() {
      this.isBusy = true;
      let payload = {
        customFilterRequest: this.filter,
        SearchProduct: this.filterTable.search,
        PageNumber: this.filterTable.page,
        RecordPerPage: this.filterTable.take,
        SortColumnId: this.filterTable.SortColumnId,
        SortType: this.filterTable.SortType,
      };
      const res = await this.$report.post(
        `/RevenueReport/StorehWithChannel`,
        payload
      );

      this.items = res.data.detail.revenueBranchChannelResponses;
      this.rows = res.data.detail.totalRecord;
      this.isBusy = false;
    },
    async getAge(data) {
      this.loading = true;
      var respData = data;
      if (!data) {
        const res = await this.$report.post(
          `/RevenueReport/CustomerAgeGroup`,
          this.filter
        );
        respData = res.data.detail;
      }

      this.chartAgeLabel = await respData.map((el) => el.ageGroup);
      this.chartAgeData = [
        {
          label: "Number Of Customers",
          type: "bar",
          data: await respData.map((el) => el.numberOfCustomer),
          borderColor: hexToRgb(
            this.$store.state.theme.themeLogo.result.themePrimaryColor
          ),
          backgroundColor: hexToRgb(
            this.$store.state.theme.themeLogo.result.themePrimaryColor
          ),
          fill: false,
        },
      ];

      this.loading = false;
    },
    async getGender(data) {
      this.loading2 = true;
      var respData = data;
      if (!data) {
        const res = await this.$report.post(
          `/RevenueReport/CustomerGender`,
          this.filter
        );
        respData = res.data.detail;
      }
      this.chartGenderLabel = await respData.map((el) => el.gender);
      this.chartGenderData = [
        {
          label: "Number Of Customer",
          type: "bar",
          data: await respData.map((el) => el.numberOfCustomer),
          borderColor: hexToRgb(
            this.$store.state.theme.themeLogo.result.themePrimaryColor
          ),
          backgroundColor: hexToRgb(
            this.$store.state.theme.themeLogo.result.themePrimaryColor
          ),
          fill: false,
        },
      ];

      this.loading2 = false;
    },
    async getTotal() {
      const res = await this.$report.post(
        `/RevenueReport/StorehWithChannel/Subtotal`,
        this.filter
      );
      this.subTotal = res.data.detail;
    },
    async getProduct() {
      // this.isProductBusy = true;
      let payload = {
        customFilterRequest: this.filter,
        SearchProduct: this.filterProductTable.search,
        PageNumber: this.filterProductTable.page,
        RecordPerPage: this.filterProductTable.take,
        SortColumnId: this.filterProductTable.SortColumnId,
        SortType: this.filterProductTable.SortType,
      };
      const res = await this.$report.post(
        `/RevenueReport/RevenueProductChannel`,
        payload
      );

      this.itemsProduct = res.data.detail.revenueProductResponses;
      this.rowsProduct = res.data.detail.totalRecord;
      this.isProductBusy = false;
    },

    pagination(val) {
      this.filterTable.page = val;
      this.getStore();
    },
    paginationProduct(val) {
      this.filterProductTable.page = val;
      this.isProductBusy = true;
      this.getProduct();
    },
    handleChangeTake(val) {
      this.filterProductTable.page = 1;
      this.filterProductTable.take = val;
      this.getStore();
    },
    handleProductChangeTake(val) {
      this.filterTable.page = 1;
      this.filterTable.take = val;
      this.isProductBusy = true;
      this.getProduct();
    },
    async exportExcel(path) {
      this.$bus.$emit("showLoading");

      const res = await this.$report.post(
        `/RevenueReport/${path}`,
        this.filter,
        {
          responseType: "blob",
        }
      );
      if (path != "channel_customer_file") {
        let data = res;

        var fileURL = window.URL.createObjectURL(new Blob([data.data]));
        var fileLink = document.createElement("a");
        // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", path + `.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
      } else {
        this.confirmAlert({
          message: "Do you want to download the file now?",
          title: "Success !",
          icon: "success",
          confirmButtonText: "Go To Page",
          cancelButtonText: "Close",
        }).then((val) => {
          if (val.isConfirmed) {
            // this.deleteItem(id);
            const routeData = this.$router.resolve({
              name: "Customer Report List",
            });
            window.open(routeData.href, "_blank");
          }
        });
      }

      this.$bus.$emit("hideLoading");
    },
    filterActions() {
      if (this.filter.TransactionDateFrom || this.filter.TransactionDateTo)
        if (this.filter.LastType == 1) {
          this.filter.TransactionDateFrom = this.$moment()
            .subtract(1, "week")
            .format("YYYY-MM-DD");
          this.filter.TransactionDateTo = this.$moment().format("YYYY-MM-DD");
        } else if (this.filter.LastType == 2) {
          this.filter.TransactionDateFrom = this.$moment()
            .subtract(30, "days")
            .format("YYYY-MM-DD");
          this.filter.TransactionDateTo = this.$moment().format("YYYY-MM-DD");
        } else if (this.filter.LastType == 3) {
          this.filter.TransactionDateFrom = this.$moment()
            .subtract(1, "years")
            .format("YYYY-MM-DD");
          this.filter.TransactionDateTo = this.$moment().format("YYYY-MM-DD");
        } else {
          this.filter.TransactionDateFrom = this.$moment(
            this.filter.TransactionDateFrom
          ).format("YYYY-MM-DD");
          this.filter.TransactionDateTo = this.$moment(
            this.filter.TransactionDateTo
          ).format("YYYY-MM-DD");
        }

      this.getData();
    },
    clearFilter() {
      this.filter = {
        MemberTierFilter: [],
        ChannelFilter: [],
        BranchFilter: [],
        AgeGroupFilter: [],
        GenderFilter: [],
        ProductFilter: [],
        TransactionDateFrom: "",
        TransactionDateTo: "",
        TypeDateFilter: 3,
        LastType: 0,
      };
      this.$store.dispatch("clearFilter");
      this.$refs.sidebarFilter.hide();
      this.filterActions();
    },
    handleSearch() {
      this.isProductBusy = true;
      this.getProduct();
    },
    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    sortingChanged(ctx) {
      let index = this.fields.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;

      if (
        this.filterTable.SortType &&
        !ctx.sortDesc &&
        this.filterTable.SortColumnId == index
      ) {
        this.filterTable.SortColumnId = 0;
        this.filterTable.SortColumnId = 0;
        this.filterTable.sortBy = "";
        this.filterTable.sortDesc = "";
      } else {
        this.filterTable.SortColumnId = index;
        this.filterTable.SortType = ctx.sortDesc;
      }
      this.getStore();
    },
    sortProduct(ctx) {
      var index = 0;
      if (ctx.sortBy == "percenSalesContribution") {
        index = this.fieldsProduct.findIndex((el) => el.key == "totalSales");
        index = index + 1;
      } else {
        index = this.fieldsProduct.findIndex((el) => el.key == ctx.sortBy);
        index = index + 1;
      }
      if (
        this.filterProductTable.SortType &&
        !ctx.sortDesc &&
        this.filterProductTable.SortColumnId == index
      ) {
        this.filterProductTable.SortColumnId = 0;
        this.filterProductTable.SortColumnId = 0;
        this.filterProductTable.sortBy = "";
        this.filterProductTable.sortDesc = "";
      } else {
        this.filterProductTable.SortColumnId = index;
        this.filterProductTable.SortType = ctx.sortDesc;
      }
      this.getProduct();
    },
  },
};
</script>

<style>
.chart-title-name {
  font-size: 18px;
  font-weight: bold;
  color: black;
  /* margin-bottom: 0.75rem; */
}

.text-highlight {
  color: var(--primary-color);
  font-weight: bold;
}
</style>
